import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { PaymentService } from 'src/app/features/pay/services/payment.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { KeyboardService } from 'src/app/shared/services/keyboard.service';
import { ScannerService } from 'src/app/shared/services/scanner.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private errorService: ErrorService,
		private readonly keyboardService: KeyboardService,
		private readonly paymentService: PaymentService,
		private readonly scannerService: ScannerService,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.includes('communications/banner/list') || request.url.includes('clear-hw-session'))
			return next.handle(request);
		return next.handle(request).pipe(
			catchError((error: HttpErrorResponse) => {
				if (request.url.includes('/cash/prepare')) this.paymentService.cashClose().subscribe();

				this.errorService.setOriginalRequest(request);
				this.errorService.setError();
				this.errorService.setRetrying(false);
				this.keyboardService.hide();
				this.scannerService.setScannerStatus(false);
				return throwError(() => error);
			}),
		);
	}
}
