import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { PurchaseType } from 'src/app/core/enums/purchase-type.enum';
import { LanguageModalComponent } from 'src/app/shared/components/language-modal/language-modal.component';
import { BannerService } from 'src/app/shared/services/banner.service';
import { IdleService } from 'src/app/shared/services/idle.service';
import { PurchaseService } from 'src/app/shared/services/purchase.service';
import { StepperService } from 'src/app/shared/services/stepper.service';
import { TimeoutService } from 'src/app/shared/services/timeout.service';
import { KioskService } from '../../services/kiosk.service';
import { LanguageService } from '../../services/language.service';

@Component({
	selector: 'app-start',
	templateUrl: './start.component.html',
	styleUrls: ['./start.component.scss'],
	host: {
		class: 'main',
	},
})
export class StartComponent implements OnInit, OnDestroy {
	step = 1;
	showImage = 0;
	PurchaseType = PurchaseType;
	banners: any[] = [];
	showItem: boolean = false;

	private destroy$ = new Subject<void>();

	constructor(
		private readonly dialog: MatDialog,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
		private readonly purchaseService: PurchaseService,
		private readonly stepperService: StepperService,
		private readonly timeoutService: TimeoutService,
		readonly bannerService: BannerService,
		private readonly idleService: IdleService,
		private languageService: LanguageService,
		private kioskService: KioskService,
	) {
		this.kioskService
			.getKioskInformation()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (kiosk: any) => {
					console.log(kiosk);
					this.languageService.setAllLanguages(kiosk.languages.sort((a: any, b: any) => a.order - b.order));
					this.purchaseService.setPurchase({ isTPVActive: kiosk.tpv_active });
				},
				error: () => {},
			});
	}

	ngOnInit(): void {
		this.showItem = true;
		this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
			next: (event) => {
				if (event instanceof NavigationEnd) {
					const url = event.url;
					this.showItem = false;

					if (url.includes('/start/2'))
						setTimeout(() => {
							this.showItem = true;
						}, 500);
				}
			},
		});

		this.bannerService.getBanners().subscribe({
			next: (banners) => {
				this.banners = banners.info.data.map((item: any) => ({ ...item, image: this.formatImage(item.image) }));
				this.sortArrayByTitleNumber(this.banners);
				this.showImage = 0;
				this.bannerService.setBanners(this.banners);
			},
			error: () => {
				this.banners = [];
			},
		});

		this.idleService.clearHardware().subscribe({
			next: () => {
				this.idleService.getHardwareStatus().subscribe((response: any) => {
					if (response.data?.status?.errors?.printer) this.idleService.setHardwareStatus(false);
				});
			},
			error: () => {
				this.idleService.setHardwareStatus(false);
			},
		});

		this.route.params.pipe(takeUntil(this.destroy$)).subscribe({
			next: (value: any) => (this.step = value ? Number(value.step) : 1),
			error: () => {
				this.step = 1;
			},
		});

		this.step =
			Number(this.route.snapshot.paramMap.get('step')) === 0 ? 1 : Number(this.route.snapshot.paramMap.get('step'));

		setInterval(() => {
			this.showImage++;
			if (this.showImage === this.banners.length) this.showImage = 0;
		}, 8000);

		this.stepperService.step$.pipe(takeUntil(this.destroy$)).subscribe({
			next: (step) => {
				if (step === -1) {
					const timeout = setTimeout(() => {
						window.location.reload();
					}, 500);
					this.timeoutService.setTimeout(timeout);
				}
			},
			error: () => {
				this.timeoutService.clearAllTimeouts();
			},
		});
	}

	selectOption(option: PurchaseType) {
		const { isTPVActive } = this.purchaseService.getPurchase();
		this.purchaseService.resetPurchase();
		this.purchaseService.setPurchase({ purchaseType: option, isTPVActive });
	}

	openDialog() {
		this.dialog.open(LanguageModalComponent);
	}

	formatImage(base64: string): string {
		return `data:image/png;base64,${base64}`;
	}

	sortArrayByTitleNumber(array: any[]) {
		this.banners = array.sort((a, b) => {
			const numA = this.extractNumberFromTitle(a.title);
			const numB = this.extractNumberFromTitle(b.title);
			return numA - numB;
		});
	}

	extractNumberFromTitle(title: string): number {
		const parts = title.split('_');
		return parseInt(parts[1], 10);
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		this.timeoutService.clearAllTimeouts();
	}
}
